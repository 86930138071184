import React, { useState } from 'react'
import {
  DsBackdrop,
  DsBox,
  DsFab,
  DsGrow,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

interface IStoryBookFigmaButtonProps {
  figmaLink: string
  storyBookLink?: string
  isMobile: boolean
}

interface ILinkButtonProps {
  link: string
  icon: string
  text: string
  sxProps?: object
}

function LinkButton({ link, icon, text, sxProps }: ILinkButtonProps) {
  return (
    <DsFab
      onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
      sx={{
        width: 'inherit',
        height: '44px',
        fontWeight: 'normal',
        ...sxProps
      }}
    >
      <DsBox
        sx={{
          display: 'flex',
          gap: 'var(--ds-spacing-glacial)',
          alignItems: 'center'
        }}
      >
        <DsRemixIcon sx={{ fontSize: '20px' }} className={icon} />
        <DsTypography
          sx={{ color: 'var(--ds-colour-typoOnSurface)' }}
          variant='bodyBoldMedium'
        >
          {text}
        </DsTypography>
      </DsBox>
    </DsFab>
  )
}

const StoryBookFigmaButton = ({
  figmaLink,
  storyBookLink,
  isMobile
}: IStoryBookFigmaButtonProps) => {
  const [show, setShow] = useState<boolean>(false)

  const handleDocumentClick = () => {
    setShow(!show)
  }

  return (
    <>
      {isMobile ? (
        <>
          <DsFab
            onClick={handleDocumentClick}
            size={'medium'}
            color={show ? 'default' : 'secondary'}
            sx={{
              fontWeight: 'normal',
              position: 'fixed',
              bottom: '28px',
              right: '24px',
              zIndex: 900,
              '&:hover': {
                backgroundColor: show
                  ? 'var(--ds-colour-surfacePrimary) !important'
                  : 'var(--palette-secondary-main) !important'
              },
              '&:active': {
                backgroundColor: show
                  ? 'var(--ds-colour-surfacePrimary) !important'
                  : 'var(--palette-secondary-main) !important'
              }
            }}
          >
            <DsRemixIcon
              className={show ? 'ri-close-fill' : 'ri-file-list-3-line'}
            />
          </DsFab>
          <DsBackdrop
            open={show}
            onClick={() => {
              setShow(false)
            }}
            sx={{ zIndex: '15' }}
          >
            <DsGrow
              in={show}
              timeout={{ enter: 600, exit: 800 }}
              onClick={e => e.stopPropagation()} // Prevent click events on the backdrop from propagating to the LinkButton
            >
              <DsStack
                alignItems={'flex-end'}
                style={{ position: 'fixed', bottom: '100px', right: '24px' }}
              >
                {storyBookLink && (
                  <LinkButton
                    text={'Open in Storybook'}
                    icon={'ri-booklet-line'}
                    link={storyBookLink}
                    sxProps={{ mb: 'var(--ds-spacing-bitterCold)' }}
                  />
                )}
                <LinkButton
                  text={'Open in Figma'}
                  icon={'ri-figma-line'}
                  link={figmaLink}
                />
              </DsStack>
            </DsGrow>
          </DsBackdrop>
        </>
      ) : (
        <DsBox
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 'var(--ds-spacing-glacial)',
            mt: { md: '-40px', lg: '-22px' }
          }}
        >
          {storyBookLink && (
            <LinkButton
              text={'Open in Storybook'}
              icon={'ri-booklet-line'}
              link={storyBookLink}
            />
          )}

          <LinkButton
            text={'Open in Figma'}
            icon={'ri-figma-line'}
            link={figmaLink}
          />
        </DsBox>
      )}
    </>
  )
}

export default StoryBookFigmaButton
