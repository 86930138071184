import React from 'react'
import { DsBox, DsSkeleton } from '@am92/react-design-system'

import { COMPONENT_DEFAULT_WIDTH } from '../Constants/THEME'

interface ISkeletonLoader {
  componentWidth?: boolean
}

const SkeletonLoader = ({ componentWidth = false }: ISkeletonLoader) => {
  const commonSkeletonStyles = {
    background: 'var(--ds-colour-surfaceSecondary)',
    transform: 'none'
  }

  const renderSkeletonRow = (
    heights: number[],
    widths: Record<string, string>[],
    borderRadii: string[]
  ) =>
    heights.map((height, idx) => (
      <DsSkeleton
        key={idx}
        animation='wave'
        sx={{
          height: `${height}px`,
          width: widths[idx],
          borderRadius: borderRadii[idx],
          ...commonSkeletonStyles
        }}
      />
    ))

  const renderColumnSkeleton = () => (
    <DsBox
      sx={{
        display: 'flex',
        gap: 'var(--ds-spacing-mild)',
        flexDirection: 'column',
        width: '50%'
      }}
    >
      <DsSkeleton
        animation='wave'
        sx={{
          height: '170px',
          width: '100%',
          borderRadius: 'var(--ds-radius-glacial)',
          ...commonSkeletonStyles
        }}
      />
      <DsSkeleton
        animation='wave'
        sx={{
          height: '20px',
          width: '100%',
          borderRadius: 'var(--ds-radius-quickFreeze)',
          ...commonSkeletonStyles
        }}
      />
    </DsBox>
  )

  return (
    <DsBox
      sx={{
        width: {
          xs: '100%',
          md: componentWidth ? COMPONENT_DEFAULT_WIDTH : '100%'
        },
        marginY: {
          xs: 'var(--ds-spacing-pleasant)',
          lg: 'calc(var(--ds-spacing-tepid) + var(--ds-spacing-mild))'
        }
      }}
    >
      <DsSkeleton
        animation='wave'
        sx={{
          height: '40px',
          width: { xs: '79.61%', md: '58.57%' },
          borderRadius: 'var(--ds-radius-glacial)',
          ...commonSkeletonStyles,
          marginBottom: 'var(--ds-spacing-mild)'
        }}
      />
      <DsBox
        sx={{
          padding: 'var(--ds-spacing-mild)',
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--ds-spacing-mild)'
        }}
      >
        {renderSkeletonRow(
          [28, 28, 28, 28],
          [
            { xs: '54.85%', md: '66.25%' },
            { xs: '80.85%', md: '91.42%' },
            { xs: '70.87%', md: '79.82%' },
            { xs: '80.85%', md: '91.42%' }
          ],
          [
            'var(--ds-radius-quickFreeze)',
            'var(--ds-radius-quickFreeze)',
            'var(--ds-radius-quickFreeze)',
            'var(--ds-radius-quickFreeze)'
          ]
        )}
      </DsBox>
      <DsBox
        sx={{
          marginTop: 'var(--ds-spacing-tropical)'
        }}
      >
        <DsSkeleton
          animation='wave'
          sx={{
            height: '40px',
            width: { xs: '92.23%', md: '71.42%' },
            borderRadius: 'var(--ds-radius-glacial)',
            ...commonSkeletonStyles,
            marginBottom: 'var(--ds-spacing-tropical)'
          }}
        />
        <DsBox
          sx={{
            display: 'flex',
            gap: 'var(--ds-spacing-mild)',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {renderColumnSkeleton()}
          {renderColumnSkeleton()}
        </DsBox>
        <DsBox
          sx={{
            marginTop: 'var(--ds-spacing-tropical)',
            display: 'flex',
            gap: 'var(--ds-spacing-mild)',
            flexDirection: 'column'
          }}
        >
          {renderSkeletonRow(
            [28, 28, 28, 28],
            [
              { xs: '54.85%', md: '66.25%' },
              { xs: '80.58%', md: '92.32%' },
              { xs: '70.87%', md: '79.82%' },
              { xs: '80.58%', md: '92.32%' }
            ],
            [
              'var(--ds-radius-quickFreeze)',
              'var(--ds-radius-quickFreeze)',
              'var(--ds-radius-quickFreeze)',
              'var(--ds-radius-quickFreeze)'
            ]
          )}
          <DsBox
            sx={{
              display: 'flex',
              gap: 'var(--ds-spacing-mild)',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {renderColumnSkeleton()}
            {renderColumnSkeleton()}
          </DsBox>
        </DsBox>
      </DsBox>
    </DsBox>
  )
}

export default SkeletonLoader
