export const CHECKBOX1 = [
  {
    src: new URL(
      '~/src/Assets/images/checkbox-anatomy1.svg?as=webp',
      import.meta.url
    ).href,
    alt: 'Checkbox Image',
    as: 'image/webp'
  },
  {
    src: new URL('~/src/Assets/images/checkbox-anatomy1.svg', import.meta.url)
      .href,
    alt: 'Checkbox Image',
    as: 'image/svg'
  }
]

export const CHECKBOX2 = [
  {
    src: new URL(
      '~/src/Assets/images/checkbox-anatomy2.svg?as=webp',
      import.meta.url
    ).href,
    alt: 'Checkbox Image',
    as: 'image/webp'
  },
  {
    src: new URL('~/src/Assets/images/checkbox-anatomy2.svg', import.meta.url)
      .href,
    alt: 'Checkbox Image',
    as: 'image/svg'
  }
]

export const CHECKBOX3 = [
  {
    src: new URL(
      '~/src/Assets/images/checkbox-anatomy3.svg?as=webp',
      import.meta.url
    ).href,
    alt: 'Checkbox Image',
    as: 'image/webp'
  },
  {
    src: new URL('~/src/Assets/images/checkbox-anatomy3.svg', import.meta.url)
      .href,
    alt: 'Checkbox Image',
    as: 'image/svg'
  }
]

export const CHECKBOX_GUIDELINE = [
  [
    [
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline1-1.svg?as=webp',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/webp'
      },
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline1-1.svg',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/svg'
      }
    ],
    [
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline1-2.svg?as=webp',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/webp'
      },
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline1-2.svg',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/svg'
      }
    ]
  ],
  [
    [
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline2-1.svg?as=webp',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/webp'
      },
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline2-1.svg',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/svg'
      }
    ],
    [
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline2-2.svg?as=webp',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/webp'
      },
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline2-2.svg',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/svg'
      }
    ]
  ],
  [
    [
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline3-1.svg?as=webp',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/webp'
      },
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline3-1.svg',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/svg'
      }
    ],
    [
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline3-2.svg?as=webp',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/webp'
      },
      {
        src: new URL(
          '~/src/Assets/images/checkboxGuideline3-2.svg',
          import.meta.url
        ).href,
        alt: 'Checkbox GuideLine Image',
        as: 'image/svg'
      }
    ]
  ]
]
