{
  "v": "4.8.0",
  "meta": { "g": "LottieFiles AE 3.5.8", "a": "", "k": "", "d": "", "tc": "" },
  "fr": 23.9759979248047,
  "ip": 0,
  "op": 95.9999916909097,
  "w": 576,
  "h": 228,
  "nm": "comp-illustrations (1) 3",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 288,
      "h": 40,
      "u": "",
      "p": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASAAAAAoCAYAAABepydZAAAACXBIWXMAAAABAAAAAQBPJcTWAAAAJHpUWHRDcmVhdG9yAAAImXNMyU9KVXBMK0ktUnBNS0tNLikGAEF6Bs5qehXFAAACJklEQVR4nO3drXLyQBhA4bOZwVSAwFQj4B56/9N7ABGNiWgEpmI/sZsMfwHCkGT6cZ6ZmhZo1JkN2eQNPCHG+AEs888CmD/zOZL+tBr4ASqgCiEc+n5A6PPiGOMSWAGfff+RpP/eHihDCNWjb3goQDk8G9KKR5JuqYDtIyG6GaAY4wxYk1Y9ktRHCexCCL9dL+gMUI7PF36/I+l5NfDdFaGrAYoxzknxmQ14YJLewy8pQvX5H4rzXxytfIyPpFeYAV+5LSdOAmR8JA3kaoTOV0Br/M5H0jDmpMa02gAd7fGRpKGscmuA0xXQZoKDkfR+2tYU0K5+3GQoaQzLZhXUrIA89ZI0phVAkW8s9d4uSWP6jDF+FHjqJWkaSwMkaSrLgvQ8H0ka26LAjYeSpjG/uBdMksZigCRNxgBJmowBkjQZAyRpMgXpkYmSNLa6IM31kaSx/RSkERqSNLbKAEmaSlXkcar7qY9E0lvZhxAOzVWwctJDkfRuSsiX4fMIVU/FJI2hasY2H+8D2k50MJLeS9uaNkC5SJ6KSRpS2ax+4HIn9A43JkoaRk1qTOskQHmA/DdplrMkvUozH/6kLRf3ghkhSS92NT4AoesdR3PifWKipGfVdMQHbgQI2gitcW6YpP5KYNcVH7gToEaeYrjBCRqS7quA7fHVri4PBaiRQ7TCQYaSLu05u8x+T68ANfI01Wae/AK/J5LeUU16nE9F2t186PsB/wAtG4xGzVh+EwAAAABJRU5ErkJggg==",
      "e": 1
    }
  ],
  "fonts": {
    "list": [
      {
        "fName": "Lato-Bold",
        "fFamily": "Lato",
        "fStyle": "Bold",
        "ascent": 74.2996215820312
      }
    ]
  },
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Cursor Outlines",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.1], "y": [1] },
              "o": { "x": [0.3], "y": [0] },
              "t": 0,
              "s": [10]
            },
            {
              "i": { "x": [0.1], "y": [1] },
              "o": { "x": [0.3], "y": [0] },
              "t": 12,
              "s": [-10]
            },
            {
              "i": { "x": [0.1], "y": [1] },
              "o": { "x": [0.3], "y": [0] },
              "t": 30,
              "s": [-10]
            },
            { "t": 39.999996537879, "s": [10] }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 0,
              "s": [384.754, 156.526, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 0.667 },
              "o": { "x": 0.333, "y": 0.333 },
              "t": 12,
              "s": [197.004, 124.526, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 30,
              "s": [197.004, 124.526, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            { "t": 39.999996537879, "s": [384.754, 156.526, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [11.754, 14.783, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
              "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
              "t": 12,
              "s": [100, 100, 100]
            },
            {
              "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
              "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
              "t": 18,
              "s": [95, 95, 100]
            },
            { "t": 23.9999979227274, "s": [100, 100, 100] }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, -1.783],
                    [0, 0],
                    [-1.27, 1.225],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.258, 1.26]
                  ],
                  "o": [
                    [-1.26, -1.262],
                    [0, 0],
                    [0, 1.765],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.78, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-6.338, -11.521],
                    [-9.754, -10.108],
                    [-9.754, 10.119],
                    [-6.365, 11.558],
                    [-1.444, 6.808],
                    [-1.388, 6.759],
                    [7.081, 6.759],
                    [8.496, 3.347]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 2, "ix": 5 },
              "lc": 1,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [11.754, 14.783], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, -0.891],
                    [0, 0],
                    [-0.635, 0.613],
                    [0, 0],
                    [0, 0],
                    [-0.241, 0],
                    [0, 0],
                    [0.629, 0.63]
                  ],
                  "o": [
                    [-0.63, -0.631],
                    [0, 0],
                    [0, 0.882],
                    [0, 0],
                    [0, 0],
                    [0.182, -0.158],
                    [0, 0],
                    [0.89, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-6.878, -10.817],
                    [-8.586, -10.111],
                    [-8.586, 10.116],
                    [-6.891, 10.835],
                    [-1.952, 6.067],
                    [-1.875, 6.001],
                    [-1.22, 5.756],
                    [7.25, 5.756],
                    [7.956, 4.05]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.156862745098, 0.156862745098, 0.156862745098, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [11.586, 14.786], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 239.999979227274,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 5,
      "nm": "l",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 31,
              "s": [0]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 34,
              "s": [100]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 37,
              "s": [100]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 40,
              "s": [0]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 43,
              "s": [0]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 46,
              "s": [100]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 49,
              "s": [100]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 52,
              "s": [0]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 55,
              "s": [0]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.167], "y": [0] },
              "t": 58,
              "s": [100]
            },
            {
              "i": { "x": [0.6], "y": [1] },
              "o": { "x": [0.6], "y": [0] },
              "t": 61,
              "s": [0]
            },
            { "t": 63.9999944606064, "s": [100] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [189.981, 112.462, 0], "ix": 2 },
        "a": { "a": 0, "k": [3.106, -9.288, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "t": {
        "d": {
          "k": [
            {
              "s": {
                "s": 21,
                "f": "Lato-Bold",
                "t": "l",
                "j": 0,
                "tr": 5,
                "lh": 25.2,
                "ls": 0,
                "fc": [1, 1, 1]
              },
              "t": 0
            }
          ]
        },
        "p": {},
        "m": { "g": 1, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
        "a": []
      },
      "ip": 30.9999973168562,
      "op": 65.9999942875004,
      "st": 30.9999973168562,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Text Outlines",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [245, 114, 0], "ix": 2 },
        "a": { "a": 0, "k": [57, 8, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.1, "y": 1 },
                    "o": { "x": 0.3, "y": 0 },
                    "t": 65,
                    "s": [
                      {
                        "i": [
                          [-2.209, 0],
                          [0, 0],
                          [0, -2.209],
                          [0, 0],
                          [2.209, 0],
                          [0, 0],
                          [0, 2.209],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [2.209, 0],
                          [0, 0],
                          [0, 2.209],
                          [0, 0],
                          [-2.209, 0],
                          [0, 0],
                          [0, -2.209]
                        ],
                        "v": [
                          [-53, -8],
                          [-52.469, -8],
                          [-48.469, -4],
                          [-48.469, 4],
                          [-52.469, 8],
                          [-53, 8],
                          [-57, 4],
                          [-57, -4]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 74.9999935085232,
                    "s": [
                      {
                        "i": [
                          [-2.209, 0],
                          [0, 0],
                          [0, -2.209],
                          [0, 0],
                          [2.209, 0],
                          [0, 0],
                          [0, 2.209],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [2.209, 0],
                          [0, 0],
                          [0, 2.209],
                          [0, 0],
                          [-2.209, 0],
                          [0, 0],
                          [0, -2.209]
                        ],
                        "v": [
                          [-53, -8],
                          [53, -8],
                          [57, -4],
                          [57, 4],
                          [53, 8],
                          [-53, 8],
                          [-57, 4],
                          [-57, -4]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [57, 8], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 65.9999942875004,
      "op": 304.999973601328,
      "st": 64.9999943740534,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "Search Icon Outlines",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [165.999, 113.999, 0], "ix": 2 },
        "a": { "a": 0, "k": [8.582, 8.582, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.002, 1.517],
                    [3.222, 0],
                    [0, -3.224],
                    [-3.224, 0],
                    [-1.088, 1.058]
                  ],
                  "o": [
                    [1.058, -1.088],
                    [0, -3.224],
                    [-3.224, 0],
                    [0, 3.222],
                    [1.517, 0.002],
                    [0, 0]
                  ],
                  "v": [
                    [3.355, 3.23],
                    [5.001, -0.832],
                    [-0.832, -6.666],
                    [-6.666, -0.832],
                    [-0.832, 5.001],
                    [3.23, 3.355]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 1,
              "ty": "sh",
              "ix": 2,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [1.064, -1.327],
                    [0, 0],
                    [0.326, -0.325],
                    [0.326, 0.326],
                    [0, 0],
                    [1.701, 0.002],
                    [0, 4.14],
                    [-4.14, 0],
                    [0, -4.14]
                  ],
                  "o": [
                    [0, 0],
                    [0.326, 0.326],
                    [-0.325, 0.326],
                    [0, 0],
                    [-1.327, 1.064],
                    [-4.14, 0],
                    [0, -4.14],
                    [4.14, 0],
                    [0.002, 1.701]
                  ],
                  "v": [
                    [5.027, 3.848],
                    [8.006, 6.827],
                    [8.006, 8.006],
                    [6.827, 8.006],
                    [3.848, 5.027],
                    [-0.832, 6.668],
                    [-8.332, -0.832],
                    [-0.832, -8.332],
                    [6.668, -0.832]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 2",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [8.582, 8.582], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 239.999979227274,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "Search Bar",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [288, 114, 0], "ix": 2 },
        "a": { "a": 0, "k": [144, 20, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 239.999979227274,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [],
  "chars": [
    {
      "ch": "l",
      "size": 21,
      "style": "Bold",
      "w": 24.85,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [6.25, -74.3],
                      [6.25, 0],
                      [18.6, 0],
                      [18.6, -74.3]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "l",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "l",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Lato"
    }
  ]
}
