{
  "v": "4.8.0",
  "meta": { "g": "LottieFiles AE 3.5.8", "a": "", "k": "", "d": "", "tc": "" },
  "fr": 23.9759979248047,
  "ip": 0,
  "op": 86.9999924698869,
  "w": 576,
  "h": 228,
  "nm": "comp-illustrations 3",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Cursor Outlines",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.1], "y": [1] },
              "o": { "x": [0.3], "y": [0] },
              "t": 0,
              "s": [0]
            },
            {
              "i": { "x": [0.1], "y": [1] },
              "o": { "x": [0.3], "y": [0] },
              "t": 10,
              "s": [-10]
            },
            {
              "i": { "x": [0.1], "y": [1] },
              "o": { "x": [0.3], "y": [0] },
              "t": 53,
              "s": [-10]
            },
            { "t": 62.9999945471595, "s": [0] }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.6, "y": 1 },
              "o": { "x": 0.6, "y": 0 },
              "t": 0,
              "s": [318.254, 163.276, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.55, "y": 0.55 },
              "o": { "x": 0.6, "y": 0.6 },
              "t": 10,
              "s": [301.254, 159.026, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.55, "y": 1 },
              "o": { "x": 0.45, "y": 0 },
              "t": 53,
              "s": [301.254, 159.026, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            { "t": 62.9999945471595, "s": [318.254, 163.276, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [11.754, 14.783, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, -1.783],
                    [0, 0],
                    [-1.27, 1.225],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.258, 1.26]
                  ],
                  "o": [
                    [-1.26, -1.262],
                    [0, 0],
                    [0, 1.765],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.78, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-6.338, -11.521],
                    [-9.754, -10.108],
                    [-9.754, 10.119],
                    [-6.365, 11.558],
                    [-1.444, 6.808],
                    [-1.388, 6.759],
                    [7.081, 6.759],
                    [8.496, 3.347]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 2, "ix": 5 },
              "lc": 1,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [11.754, 14.783], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, -0.891],
                    [0, 0],
                    [-0.635, 0.613],
                    [0, 0],
                    [0, 0],
                    [-0.241, 0],
                    [0, 0],
                    [0.629, 0.63]
                  ],
                  "o": [
                    [-0.63, -0.631],
                    [0, 0],
                    [0, 0.882],
                    [0, 0],
                    [0, 0],
                    [0.182, -0.158],
                    [0, 0],
                    [0.89, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-6.878, -10.817],
                    [-8.586, -10.111],
                    [-8.586, 10.116],
                    [-6.891, 10.835],
                    [-1.952, 6.067],
                    [-1.875, 6.001],
                    [-1.22, 5.756],
                    [7.25, 5.756],
                    [7.956, 4.05]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.156862745098, 0.156862745098, 0.156862745098, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [11.586, 14.786], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 239.999979227274,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Info",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [288, 147, 0], "ix": 2 },
        "a": { "a": 0, "k": [10.25, 10.25, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 6,
              "s": [100, 100, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 16,
              "s": [97, 97, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 53,
              "s": [97, 97, 100]
            },
            { "t": 62.9999945471595, "s": [100, 100, 100] }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-1, -1],
                    [1, -1],
                    [1, 5],
                    [-1, 5]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 1,
              "ty": "sh",
              "ix": 2,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-1, -5],
                    [1, -5],
                    [1, -3],
                    [-1, -3]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 2",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 2,
              "ty": "sh",
              "ix": 3,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-2.122, 0],
                    [-1.5, 1.5],
                    [0, 2.122],
                    [1.5, 1.5],
                    [2.122, 0],
                    [1.5, -1.5],
                    [0, -2.122],
                    [-1.5, -1.5]
                  ],
                  "o": [
                    [2.122, 0],
                    [1.5, -1.5],
                    [0, -2.122],
                    [-1.5, -1.5],
                    [-2.122, 0],
                    [-1.5, 1.5],
                    [0, 2.122],
                    [1.5, 1.5]
                  ],
                  "v": [
                    [0, 8],
                    [5.657, 5.657],
                    [8, 0],
                    [5.657, -5.657],
                    [0, -8],
                    [-5.657, -5.657],
                    [-8, 0],
                    [-5.657, 5.657]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 3",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 3,
              "ty": "sh",
              "ix": 4,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [5.523, 0],
                    [0, 5.523],
                    [-5.523, 0],
                    [0, -5.523]
                  ],
                  "o": [
                    [-5.523, 0],
                    [0, -5.523],
                    [5.523, 0],
                    [0, 5.523]
                  ],
                  "v": [
                    [0, 10],
                    [-10, 0],
                    [0, -10],
                    [10, 0]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 4",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [10.25, 10.25], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 5,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 237.99997940038,
      "st": -1.99999982689395,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Box",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [287.5, 124.4, 0], "ix": 2 },
        "a": { "a": 0, "k": [87, 55.399, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 6,
              "s": [0, 0, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 12,
              "s": [102, 102, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 18,
              "s": [99, 99, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 24,
              "s": [100, 100, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 56,
              "s": [100, 100, 100]
            },
            {
              "i": { "x": [0.6, 0.6, 0.6], "y": [1, 1, 1] },
              "o": { "x": [0.6, 0.6, 0.6], "y": [0, 0, 0] },
              "t": 63,
              "s": [104, 104, 100]
            },
            { "t": 67.9999941143943, "s": [0, 0, 100] }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0.779, 1.018],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.822, 0.985]
                  ],
                  "v": [
                    [-2.042, 6.114],
                    [-12, -6.899],
                    [12, -6.899],
                    [1.082, 6.18]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [87.5, 48.899], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 60, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-2.209, 0],
                    [0, 0],
                    [0, -2.209],
                    [0, 0],
                    [2.209, 0],
                    [0, 0],
                    [0, 2.209],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [2.209, 0],
                    [0, 0],
                    [0, 2.209],
                    [0, 0],
                    [-2.209, 0],
                    [0, 0],
                    [0, -2.209]
                  ],
                  "v": [
                    [-71.5, -9],
                    [71.5, -9],
                    [75.5, -5],
                    [75.5, 5],
                    [71.5, 9],
                    [-71.5, 9],
                    [-75.5, 5],
                    [-75.5, -5]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [87.5, 21], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-4.418, 0],
                    [0, 0],
                    [0, -4.418],
                    [0, 0],
                    [4.418, 0],
                    [0, 0],
                    [0, 4.418],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [4.418, 0],
                    [0, 0],
                    [0, 4.418],
                    [0, 0],
                    [-4.418, 0],
                    [0, 0],
                    [0, -4.418]
                  ],
                  "v": [
                    [-79.5, -21],
                    [79.5, -21],
                    [87.5, -13],
                    [87.5, 13],
                    [79.5, 21],
                    [-79.5, 21],
                    [-87.5, 13],
                    [-87.5, -13]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [87.5, 21], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 60, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 5.99999948068185,
      "op": 245.999978707956,
      "st": 5.99999948068185,
      "bm": 0
    }
  ],
  "markers": []
}
