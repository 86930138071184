import React from 'react'
import { DsBox, DsRemixIcon, DsTypography } from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import { INavBarItem } from '../Constants/NAVBAR'
import { FADE_IN_ANIMATION } from '../Constants/THEME'

export interface ISubnavbarProps extends IWithRouterProps {
  items: INavBarItem[]
  isActive: (item: INavBarItem) => boolean
  onClose?: () => void
}

const Subnavbar = ({ items, navigate, isActive, onClose }: ISubnavbarProps) => {
  const handleItemClick = (item: INavBarItem) => {
    if (item.link) {
      navigate(item.link)
      if (onClose) {
        onClose()
      }
    }
  }

  const renderItems = (items: INavBarItem[]) =>
    items.map(item => {
      const isItemActive = isActive(item)

      return (
        <DsBox key={item.label} sx={{ mb: 'var(--ds-spacing-quickFreeze)' }}>
          <DsBox
            sx={{
              backgroundColor:
                isItemActive && !item.children
                  ? 'var(--ds-colour-surfaceBackground)'
                  : 'transparent',
              borderRadius: isItemActive ? 'var(--ds-radius-glacial)' : 'none',
              display: 'flex',
              alignItems: 'center',
              cursor: !item.children ? 'pointer' : 'auto',
              padding:
                'calc(var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze)) var(--ds-spacing-bitterCold)',
              '&:hover': {
                backgroundColor: !item.children
                  ? 'var(--ds-colour-surfaceBackground)'
                  : 'transparent',
                borderRadius: 'var(--ds-radius-glacial)'
              },
              mb: 'var(--ds-spacing-quickFreeze)'
            }}
            onClick={() => handleItemClick(item)}
          >
            {item.icon && (
              <DsRemixIcon className={item.icon} sx={{ fontSize: '16px' }} />
            )}
            <DsTypography
              variant={
                isItemActive || item.children
                  ? 'bodyBoldSmall'
                  : 'bodyRegularSmall'
              }
              sx={{
                ml: 'calc(var(--ds-spacing-frostbite) - var(--ds-spacing-deepFreeze))'
              }}
            >
              {item.label}
            </DsTypography>
          </DsBox>
          {item.children && item.children.length > 0 && (
            <DsBox
              sx={{
                pl: 'var(--ds-spacing-frostbite)',
                mt: 'var(--ds-spacing-quickFreeze)'
              }}
            >
              {renderItems(item.children)}
            </DsBox>
          )}
        </DsBox>
      )
    })

  return (
    <DsBox
      key={items && items.length ? items[0].label : 'landing'}
      sx={{
        ...FADE_IN_ANIMATION,
        py: 'var(--ds-spacing-mild)',
        px: 'var(--ds-spacing-bitterCold)',
        animationDuration: '0.8s'
      }}
    >
      {renderItems(items)}
    </DsBox>
  )
}

export default withRouter(Subnavbar)
