import React, { useState } from 'react'
import {
  DsAccordion,
  DsBox,
  DsDivider,
  DsFab,
  DsImage,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

import AnatomyFullScreen from './AnatomyFullScreen'

interface IAnatomyStructureProps {
  anatomyStructureProp: {
    src: string
    alt: string
    as: string
  }[]
}

export const AnatomyStructure = ({
  anatomyStructureProp
}: IAnatomyStructureProps) => {
  const [expanded, setExpanded] = useState(false)
  const [viewFullScreen, setViewFullScreen] = useState(false)
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 })

  const handleAccordionChange = (
    _event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded)
  }

  const closeFullScreen = () => {
    setViewFullScreen(false)
  }

  const handleViewClick = (e: React.MouseEvent) => {
    setClickPosition({ x: e.clientX, y: e.clientY })
    setViewFullScreen(true)
  }

  return (
    <DsBox
      sx={{
        width: '100%',
        my: {
          xs: 'var(--ds-spacing-tropical)',
          md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))'
        },
        borderRadius: 'var(--ds-radius-bitterCold)',
        border: '1px solid var(--ds-colour-strokeDefault)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <DsAccordion
        sx={{
          display: { xs: 'none', md: 'block' },
          background: 'var(--ds-colour-surfaceBackground)',
          width: '100%',
          '&.Mui-expanded': {
            margin: 0
          },
          '& .MuiAccordionDetails-root': {
            padding: 0,
            borderBottom: 'none'
          },
          '& .MuiAccordionSummary-root': {
            padding: 0
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            backgroundColor: 'var(--ds-colour-surfaceBackground)'
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            top: '0',
            right: '0'
          },
          '& .MuiCollapse-wrapper': {
            backgroundColor: '#FFFFFF'
          }
        }}
        expanded={expanded}
        onChange={handleAccordionChange}
        expandIcon={
          <DsBox
            sx={{
              display: 'flex',
              width: '32px',
              height: '32px',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              borderRadius: 'var(--ds-spacing-cool)',
              padding: 'var(--ds-spacing-quickFreeze)',
              m: 'var(--ds-spacing-mild)'
            }}
          >
            <DsRemixIcon className='ri-arrow-down-s-line' />
          </DsBox>
        }
        header={
          <DsBox sx={{ width: '100%' }}>
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 'var(--ds-spacing-mild)',
                gap: 'var(--ds-spacing-glacial)'
              }}
            >
              <DsRemixIcon
                className='ri-artboard-2-line'
                sx={{
                  fontSize: '28px'
                }}
              />
              <DsTypography
                variant='headingBoldMedium'
                color='var(--ds-colour-typoPrimary)'
              >
                Anatomy & Structure
              </DsTypography>
            </DsBox>
            <DsDivider
              sx={{
                opacity: 0.6,
                ml: 'var(--ds-spacing-mild)',
                mr: 'var(--ds-spacing-glacial)'
              }}
            />
            {!expanded && (
              <DsBox sx={{ p: 'var(--ds-spacing-mild)', width: '100%' }}>
                <DsTypography
                  variant='bodyRegularMedium'
                  color='var(--ds-colour-typoSecondary)'
                >
                  View parts and structure of the component
                </DsTypography>
              </DsBox>
            )}
          </DsBox>
        }
        summary={
          <DsImage
            style={{
              marginTop: 'var(--ds-spacing-mild)'
            }}
            width={'100%'}
            srcSet={anatomyStructureProp}
          />
        }
      />

      <DsBox
        sx={{
          width: '100%',
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 'var(--ds-spacing-mild)'
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 'var(--ds-spacing-glacial)'
            }}
          >
            <DsRemixIcon
              className='ri-artboard-2-line'
              sx={{
                fontSize: '28px'
              }}
            />
            <DsTypography
              variant='headingBoldMedium'
              color='var(--ds-colour-typoPrimary)'
            >
              Anatomy & Structure
            </DsTypography>
          </DsBox>
          <DsBox>
            <DsFab
              size='small'
              sx={{
                fontWeight: 'normal',
                gap: '8px',
                height: '44px',
                width: '88px',
                zIndex: 1
              }}
              onClick={handleViewClick}
            >
              <DsRemixIcon
                className='ri-fullscreen-line'
                sx={{
                  fontSize: '20px'
                }}
              />
              <DsTypography variant='bodyBoldMedium'>View</DsTypography>
            </DsFab>
          </DsBox>
        </DsBox>
      </DsBox>
      <AnatomyFullScreen
        viewFullScreen={viewFullScreen}
        closeModal={closeFullScreen}
        clickPosition={clickPosition}
        anatomyStructureProp={anatomyStructureProp}
      />
    </DsBox>
  )
}
