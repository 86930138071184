import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  DsBox,
  DsCollapse,
  DsDivider,
  DsFab,
  DsMenu,
  DsMenuItem,
  DsPaper,
  DsRemixIcon,
  DsStack,
  DsTypography,
  SupportedColorScheme
} from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '../Hocs/withRouter'

import Subnavbar from './Subnavbar'
import SubZeroIcon from './SubZeroIcon'

import { setThemeSchemeAction } from '../Redux/Theme/Actions'
import { getThemeReducer } from '../Redux/Theme/Selectors'

import {
  INavBarItem,
  NAVBAR,
  NAVBAR_WIDTH,
  SUBNAVBAR_WIDTH,
  themeOptions
} from '../Constants/NAVBAR'
import {
  SLIDE_IN_DOWN_ANIMATION,
  SLIDE_IN_TOP_ANIMATION
} from '../Constants/THEME'
import { TAppDispatch, TAppStore } from '../Configurations/AppStore'

interface INavbarProps extends TPropsFromRedux, IWithRouterProps {
  onClose?: () => void
  open?: boolean
  landingScreen?: boolean
}
const Navbar = ({
  navigate,
  location,
  actions,
  theme,
  open,
  onClose,
  landingScreen = false
}: INavbarProps) => {
  const [selectedItem, setSelectedItem] = useState<INavBarItem | null>(null)
  const [activeItem, setActiveItem] = useState<INavBarItem | null>(null)
  const [anchor, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchor)
  const [showSubNavbar, setShowSubNavbar] = useState<boolean>(!landingScreen)
  const [iconAnimation, setIconAnimation] = useState<{ [key: string]: any }>({})

  const findActiveNavItem = (
    items: INavBarItem[],
    pathname: string
  ): INavBarItem | null => {
    for (const item of items) {
      if (item.link === pathname) {
        return item
      }

      if (item.children) {
        const activeChild = findActiveNavItem(item.children, pathname)
        if (activeChild) {
          return item
        }
      }
    }
    return null
  }

  useEffect(() => {
    const activeNavItem = findActiveNavItem(NAVBAR, location.pathname)
    setSelectedItem(activeNavItem)
    setActiveItem(activeNavItem)
  }, [location.pathname])

  const handleShowSubmenu = (item: INavBarItem) => {
    const activeNavItem = findActiveNavItem(NAVBAR, location.pathname)

    setSelectedItem(item.children ? item : activeNavItem)
    setShowSubNavbar(landingScreen ? !!item.children : true)

    if (open && !item.children && item.link) {
      navigate(item.link)
      if (onClose) {
        onClose()
      }
    }
  }

  const handleMenuClick = (item: INavBarItem) => {
    if (item.link) {
      navigate(item.link)
      if (onClose) {
        onClose()
      }
    } else if (item.children) {
      handleMenuClick(item.children[0])
    }
  }

  const handleMouseLeave = () => {
    if (landingScreen) {
      setShowSubNavbar(false)
    } else {
      const activeNavItem = findActiveNavItem(NAVBAR, location.pathname)
      setSelectedItem(activeNavItem)
    }
  }

  const isActive = (item: INavBarItem): boolean => {
    const isActiveItem = activeItem !== null && activeItem === item
    const isLinkActive = item.link ? location.pathname === item.link : false
    const hasActiveChild = item.children
      ? item.children.some(child => isActive(child))
      : false

    return isActiveItem || isLinkActive || hasActiveChild
  }

  const handleThemeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSchemeChange = (label: string) => {
    const schemeMap: Record<string, SupportedColorScheme> = {
      Dark: 'dark',
      Light: 'light',
      'High Contrast': 'highContrast'
    }
    const newScheme = schemeMap[label] || 'light' // Default to 'light' if label not found

    const currentThemeId =
      themeOptions.find(option => option.scheme === theme.scheme)?.id || 0
    const newThemeId =
      themeOptions.find(option => option.scheme === newScheme)?.id || 0

    setIconAnimation(
      newThemeId - currentThemeId === 0
        ? {}
        : newThemeId - currentThemeId > 0
          ? SLIDE_IN_DOWN_ANIMATION
          : SLIDE_IN_TOP_ANIMATION
    )

    actions.setThemeScheme(newScheme)
    setAnchorEl(null)
  }

  const currentThemeIcon = themeOptions.find(
    option => option.scheme === theme.scheme
  )?.icon

  return (
    <DsBox
      sx={{ display: 'flex', width: '100%' }}
      onMouseLeave={handleMouseLeave}
    >
      <DsPaper
        elevation={landingScreen ? 4 : -1}
        sx={{
          width: NAVBAR_WIDTH,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 'var(--ds-spacing-mild) var(--ds-spacing-frostbite)',
          height: '100vh',
          overflowY: 'auto',
          background: 'var(--ds-colour-surfaceBackground)',
          zIndex: 1100
        }}
      >
        <DsStack
          sx={{
            p: 'var(--ds-spacing-zero)'
          }}
        >
          {open && (
            <DsRemixIcon
              onClick={onClose}
              className='ri-close-line'
              sx={{
                fontSize: '24px',
                mb: 'var(--ds-spacing-mild)'
              }}
            />
          )}
          {NAVBAR.map((item: INavBarItem) => (
            <DsBox
              onClick={() =>
                open ? handleShowSubmenu(item) : handleMenuClick(item)
              }
              onMouseOver={() => (open ? null : handleShowSubmenu(item))}
              sx={{
                mb: 'var(--ds-spacing-cool)',
                py: 'calc(var(--ds-spacing-frostbite) - var(--ds-spacing-deepFreeze))',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                alignItems: 'center',
                width: '100%',
                backgroundColor: isActive(item)
                  ? 'var(--ds-colour-stateSelectedSecondaryPressed)'
                  : 'inherit',
                borderRadius: isActive(item)
                  ? 'var(--ds-radius-glacial)'
                  : 'inherit',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor:
                    'var(--ds-colour-stateSelectedSecondaryHover)',
                  borderRadius: 'var(--ds-radius-glacial)'
                }
              }}
              key={item.label}
            >
              {item.icon === 'home' ? (
                <DsBox
                  sx={{
                    width: '20px',
                    height: '20px'
                  }}
                >
                  <SubZeroIcon
                    color={
                      isActive(item)
                        ? '#ED1164'
                        : 'var(--ds-colour-surfaceTertiary)'
                    }
                  />
                </DsBox>
              ) : (
                <DsRemixIcon className={item.icon} fontSize={'small'} />
              )}

              <DsTypography
                variant='bodyRegularSmall'
                sx={{
                  mt: 'calc(var(--ds-radius-glacial) - var(--ds-radius-deepFreeze))'
                }}
              >
                {item.label}
              </DsTypography>
            </DsBox>
          ))}
        </DsStack>
        <DsBox
          sx={{
            width: '100%',
            textAlign: 'center'
          }}
        >
          <DsDivider
            sx={{
              mb: 'var(--ds-spacing-frostbite)'
            }}
          />
          <DsFab
            onClick={handleThemeClick}
            color='inherit'
            sx={{
              overflow: 'hidden',
              width: 'var(--ds-spacing-hot)',
              height: 'var(--ds-spacing-hot)',
              boxShadow: 'var(--ds-elevation--1, none)',
              backgroundColor: anchor
                ? 'var(--ds-colour-stateSelectedSecondaryPressed)'
                : 'var(--ds-colour-surfaceSecondary)',
              '&:hover': {
                backgroundColor: 'var(--ds-colour-stateSelectedSecondaryHover)',
                boxShadow: 'var(--ds-elevation--1, none)'
              }
            }}
          >
            {currentThemeIcon && (
              <DsRemixIcon
                className={currentThemeIcon}
                key={currentThemeIcon}
                sx={{
                  ...iconAnimation,
                  fontSize: '20px'
                }}
              />
            )}
          </DsFab>
          <DsMenu
            anchorEl={anchor}
            open={menuOpen}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              '& .MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor:
                    'var(--ds-colour-stateSelectedSecondaryHover) !important'
                },
                '&:active': {
                  backgroundColor:
                    'var(--ds-colour-stateSelectedSecondaryPressed) !important'
                }
              }
            }}
          >
            {themeOptions.map(({ icon, label }) => (
              <DsMenuItem key={label} onClick={() => handleSchemeChange(label)}>
                <DsRemixIcon
                  className={icon}
                  sx={{ fontSize: '16px', mr: 'var(--ds-spacing-quickFreeze)' }}
                />
                {label}
              </DsMenuItem>
            ))}
          </DsMenu>
        </DsBox>
      </DsPaper>
      <DsCollapse
        in={selectedItem?.children && showSubNavbar}
        timeout={{
          enter: 300,
          exit: 150
        }}
        orientation='horizontal'
        sx={{ zIndex: 1100 }}
      >
        <DsBox
          sx={{
            width: SUBNAVBAR_WIDTH,
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            zIndex: 1000,
            height: '100vh',
            overflowY: 'auto'
          }}
        >
          <Subnavbar
            items={selectedItem?.children || []}
            isActive={isActive}
            onClose={onClose}
          />
        </DsBox>
      </DsCollapse>
    </DsBox>
  )
}

const mapStateToProps = (state: TAppStore) => {
  const theme = getThemeReducer(state)
  return {
    theme
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setThemeScheme: (scheme: SupportedColorScheme) =>
      dispatch(setThemeSchemeAction(scheme))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(withRouter(Navbar))
