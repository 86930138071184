export const ABOUT_SUBZERO_MOBILE = [
  {
    src: new URL('~/src/Assets/images/aboutSubzeroMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DESIGNERS_TOOLKIT_MOBILE = [
  {
    src: new URL(
      '~/src/Assets/images/designersToolkitMobile.jpg',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DEVELOPERS_TOOLKIT_MOBILE = [
  {
    src: new URL(
      '~/src/Assets/images/developerToolkitMobile.jpg',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const FEEDBACK_MOBILE = [
  {
    src: new URL('~/src/Assets/images/feedbackMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DESIGN_TOKEN_MOBILE = [
  {
    src: new URL('~/src/Assets/images/designTokenMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ACCESSIBILITY_MOBILE = [
  {
    src: new URL('~/src/Assets/images/accessibilityMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TYPOGRAPHY_MOBILE = [
  {
    src: new URL('~/src/Assets/images/typographyMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const COLOUR_MOBILE = [
  {
    src: new URL('~/src/Assets/images/colourMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ELEVATION_MOBILE = [
  {
    src: new URL('~/src/Assets/images/elevationMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ICONOGRAPHY_MOBILE = [
  {
    src: new URL('~/src/Assets/images/iconographyMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const LAYOUTSPACING_MOBILE = [
  {
    src: new URL('~/src/Assets/images/layoutSpacingMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DATA_VIS_MOBILE = [
  {
    src: new URL('~/src/Assets/images/dataVisMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BUTTON_MOBILE = [
  {
    src: new URL('~/src/Assets/images/buttonMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const COACHMARK_MOBILE = [
  {
    src: new URL('~/src/Assets/images/buttonMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ADD_ITEM_MOBILE = [
  {
    src: new URL('~/src/Assets/images/addItemMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const CHIP_MOBILE = [
  {
    src: new URL('~/src/Assets/images/addItemMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BADGE_MOBILE = [
  {
    src: new URL('~/src/Assets/images/badgeMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const APP_BAR_MOBILE = [
  {
    src: new URL('~/src/Assets/images/badgeMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const INPUT_FIELD_MOBILE = [
  {
    src: new URL('~/src/Assets/images/inputFieldMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const CAROUSEL_MOBILE = [
  {
    src: new URL('~/src/Assets/images/inputFieldMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const AVATAR_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BREADCRUMB_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BOTTOM_SHEET_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BOTTOM_NAV_MOBILE = [
  {
    src: new URL('~/src/Assets/images/bottomNavMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const CHECKBOX_MOBILE = [
  {
    src: new URL('~/src/Assets/images/badgeMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const MENU_MOBILE = [
  {
    src: new URL('~/src/Assets/images/inputFieldMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const SWITCH_MOBILE = [
  {
    src: new URL('~/src/Assets/images/inputFieldMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const TOAST_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TAB_MOBILE = [
  {
    src: new URL('~/src/Assets/images/bottomNavMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const SLIDER_MOBILE = [
  {
    src: new URL('~/src/Assets/images/bottomNavMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DIALOG_MOBILE = [
  {
    src: new URL('~/src/Assets/images/bottomNavMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ONBOARDING_MOBILE = [
  {
    src: new URL('~/src/Assets/images/buttonMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const FAB_MOBILE = [
  {
    src: new URL('~/src/Assets/images/buttonMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const RADIO_MOBILE = [
  {
    src: new URL('~/src/Assets/images/addItemMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TAGS_MOBILE = [
  {
    src: new URL('~/src/Assets/images/addItemMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TOGGLE_MOBILE = [
  {
    src: new URL('~/src/Assets/images/badgeMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ACCORDION_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const HEADER_MOBILE = [
  {
    src: new URL('~/src/Assets/images/buttonMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DIVIDER_MOBILE = [
  {
    src: new URL('~/src/Assets/images/bottomNavMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const PROGRESS_TRACKER_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const LINK_MOBILE = [
  {
    src: new URL('~/src/Assets/images/addItemMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DATEPICKER_MOBILE = [
  {
    src: new URL('~/src/Assets/images/badgeMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TOOLTIP_MOBILE = [
  {
    src: new URL('~/src/Assets/images/inputFieldMobile.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const SEARCH_MOBILE = [
  {
    src: new URL('~/src/Assets/images/avatarMobile.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
