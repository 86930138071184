import React from 'react'
import {
  DsBox,
  DsGrow,
  DsImage,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

interface IAnatomyFullScreenProps {
  closeModal: () => void
  viewFullScreen: boolean
  clickPosition: { x: number; y: number }
  anatomyStructureProp: {
    src: string
    alt: string
    as: string
  }[]
}

const AnatomyFullScreen = ({
  closeModal,
  anatomyStructureProp,
  viewFullScreen = false,
  clickPosition
}: IAnatomyFullScreenProps) => {
  return (
    <DsGrow
      in={viewFullScreen}
      timeout={200}
      style={{
        transformOrigin: `${clickPosition.x}px ${clickPosition.y}px`
      }}
    >
      <DsBox
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          zIndex: 9999
        }}
      >
        <DsBox
          sx={{
            borderBottom: '1px solid var(--ds-colour-strokeDefault)',
            padding: '16px',
            display: 'flex',
            gap: '16px',
            alignItems: 'center'
          }}
        >
          <DsRemixIcon
            onClick={closeModal}
            className='ri-arrow-left-line'
            sx={{
              fontSize: '24px'
            }}
          />
          <DsTypography
            variant='headingBoldSmall'
            color='var(--ds-colour-typoPrimary)'
          >
            Anatomy & Structure
          </DsTypography>
        </DsBox>
        <DsBox
          sx={{
            overflow: 'scroll',
            width: '100%',
            height: '100%',
            background: '#FFFFFF'
          }}
        >
          <DsImage
            style={{
              marginTop: 'var(--ds-spacing-mild)',
              minWidth: '800px',
              minHeight: 'auto',
              paddingBottom: 'var(--ds-spacing-mild)'
            }}
            srcSet={anatomyStructureProp}
          />
        </DsBox>
      </DsBox>
    </DsGrow>
  )
}

export default AnatomyFullScreen
