import React, { useState } from 'react'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsIconButton,
  DsImage,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import withDeviceDetails, {
  IWithDeviceDetails
} from '../Hocs/withDeviceDetails'

interface ITextImage {
  src: string
  alt: string
  as: string
}
interface IGuideline {
  title: string
  description: string | JSX.Element
  image?: ITextImage[]
  imageList?: ITextImage[][]
}

interface IGuidelinesProps extends IWithDeviceDetails {
  guidelines: IGuideline[]
}

interface IGuidelineImageProps {
  isMobile: boolean
  imageList: ITextImage[][]
}

function GuidelineImage({ isMobile, imageList }: IGuidelineImageProps) {
  return (
    <DsStack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={'space-around'}
      alignItems={'center'}
      sx={{
        background: '#F1F4F7',
        py: isMobile ? 'var(--ds-spacing-bitterCold)' : 'var(--ds-spacing-mild)'
      }}
    >
      <DsIconButton
        disabled
        sx={isMobile ? { mb: 'var(--ds-spacing-warm)' } : {}}
      >
        <DsImage srcSet={imageList[0]} />
      </DsIconButton>
      {imageList[1] ? (
        <DsIconButton disabled>
          <DsImage srcSet={imageList[1]} />
        </DsIconButton>
      ) : null}
    </DsStack>
  )
}

const ComponentGuidelines = ({ guidelines, isMobile }: IGuidelinesProps) => {
  const [selectedGuideline, setSelectedGuideline] = useState<IGuideline>(
    guidelines[0]
  )

  const handleClick = (title: IGuideline) => {
    setSelectedGuideline(title)
  }

  return (
    <>
      <DsDivider />
      <DsBox
        sx={{
          width: '100%',
          borderRadius: 'var(--ds-radius-bitterCold)',
          border: '1px solid var(--ds-colour-strokeDefault)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          my: {
            xs: 'var(--ds-spacing-tropical)',
            md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))'
          }
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 'var(--ds-spacing-mild)',
            gap: 'var(--ds-spacing-glacial)'
          }}
        >
          <DsRemixIcon
            className='ri-file-list-3-line'
            sx={{
              fontSize: '28px'
            }}
          />
          <DsTypography
            variant='headingBoldMedium'
            color='var(--ds-colour-typoPrimary)'
          >
            Guidelines
          </DsTypography>
        </DsBox>
        <DsDivider
          sx={{
            opacity: 0.6,
            width: '95%',
            mx: isMobile ? 'var(--ds-spacing-frostbite)' : 'auto'
          }}
        />
        {isMobile ? (
          <>
            {guidelines.map((item, index) => {
              return (
                <DsBox
                  key={item.title}
                  sx={{
                    mb:
                      index === guidelines.length - 1
                        ? 'var(--ds-spacing-zero)'
                        : 'var(--ds-spacing-bitterCold)'
                  }}
                >
                  <DsStack
                    direction={'column'}
                    sx={{ p: 'var(--ds-spacing-cool)' }}
                  >
                    <DsTypography
                      variant='headingBoldMedium'
                      sx={{ mb: 'var(--ds-spacing-glacial)' }}
                    >
                      {item.title}
                    </DsTypography>
                    <DsTypography variant='bodyRegularLarge'>
                      {item.description}
                    </DsTypography>
                  </DsStack>
                  {item.imageList?.length && (
                    <GuidelineImage
                      isMobile={isMobile}
                      imageList={item.imageList}
                    />
                  )}
                </DsBox>
              )
            })}
          </>
        ) : (
          <>
            <DsGrid
              container
              sx={{
                width: '100%',
                padding: 'var(--ds-spacing-mild)'
              }}
            >
              <DsGrid
                item
                xs={5.5}
                sx={{
                  display: 'flex',
                  gap: 'var(--ds-spacing-glacial)',
                  flexDirection: 'column',
                  pr: 'var(--ds-spacing-warm)'
                }}
              >
                {guidelines.map(item => (
                  <DsTypography
                    key={item.title}
                    variant={
                      selectedGuideline.title === item.title
                        ? 'bodyBoldMedium'
                        : 'bodyRegularSmall'
                    }
                    color={
                      selectedGuideline.title === item.title
                        ? 'var(--ds-colour-actionSecondary)'
                        : 'none'
                    }
                    sx={{
                      borderRadius: 'var(--ds-radius-glacial)',
                      px: 'var(--ds-spacing-bitterCold)',
                      py: 'calc(var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze))',
                      backgroundColor:
                        selectedGuideline.title === item.title
                          ? 'var(--ds-colour-surfaceSecondary)'
                          : 'transparent',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleClick(item)}
                  >
                    {item.title}
                  </DsTypography>
                ))}
              </DsGrid>

              <DsGrid
                item
                xs={6.5}
                sx={{
                  display: 'flex',
                  gap: 'var(--ds-spacing-glacial)',
                  flexDirection: 'column'
                }}
              >
                {selectedGuideline && (
                  <>
                    <DsTypography variant='headingBoldMedium'>
                      {selectedGuideline.title}
                    </DsTypography>
                    <DsTypography variant='bodyRegularLarge'>
                      {selectedGuideline.description}
                    </DsTypography>
                  </>
                )}
              </DsGrid>
            </DsGrid>
            {selectedGuideline.imageList?.length ? (
              <GuidelineImage
                isMobile={isMobile}
                imageList={selectedGuideline.imageList}
              />
            ) : (
              <DsBox>
                <DsImage width={'100%'} srcSet={selectedGuideline.image} />
              </DsBox>
            )}
          </>
        )}
      </DsBox>
    </>
  )
}

export default withDeviceDetails(ComponentGuidelines)
