import React, { PureComponent, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import {
  DsBox,
  DsRemixIcon,
  DsStack,
  IwithBreakpoints
} from '@am92/react-design-system'

import withDeviceDetails, {
  IWithDeviceDetails
} from '../Hocs/withDeviceDetails'
import { IWithRouterProps } from '../Hocs/withRouter'

import HeaderIcon from '../Components/HeaderIcon'
import Loader from '../Components/Loader'
import MobileNavbar from '../Components/MobileNavbar'
import Navbar from '../Components/Navbar'

import { NAVBAR_WIDTH } from '../Constants/NAVBAR'

interface IHomePageLayoutProps
  extends IwithBreakpoints,
    IWithDeviceDetails,
    IWithRouterProps {}

class HomePageLayout extends PureComponent<IHomePageLayoutProps> {
  render() {
    const isMobile = this.props.isMobile || this.props.isTablet

    return (
      <DsBox sx={{ display: 'flex' }}>
        {!isMobile && (
          <Suspense fallback={<Loader />}>
            <DsBox
              sx={{
                zIndex: 20,
                position: 'fixed'
              }}
            >
              <DsStack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  height: '68px',
                  width: '100%',
                  zIndex: 10,
                  position: 'fixed',
                  top: '0',
                  px: 'var(--ds-spacing-hot)'
                }}
              >
                <DsRemixIcon
                  className='ri-menu-line'
                  sx={{ fontSize: '24px', cursor: 'pointer' }}
                />
                <DsBox
                  sx={{
                    width: { xs: '119px', lg: '166px' },
                    height: { xs: '24px', lg: '36px' }
                  }}
                >
                  <HeaderIcon
                    width={this.props.isMobile ? '119' : '166'}
                    height={this.props.isMobile ? '24' : '36'}
                  />
                </DsBox>
              </DsStack>
              <Navbar landingScreen={true} />
            </DsBox>
          </Suspense>
        )}

        <DsBox
          sx={{
            width: { xs: '100%', lg: `calc(100% - ${NAVBAR_WIDTH})` },
            ml: { xs: '0', lg: NAVBAR_WIDTH }
          }}
        >
          {isMobile && (
            <MobileNavbar
              position='fixed'
              hambergurColor='var(--ds-colour-iconDefault)'
            />
          )}
          <Outlet />
        </DsBox>
      </DsBox>
    )
  }
}

export default withDeviceDetails(HomePageLayout)
