import React from 'react'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

interface IInteractionItem {
  icon: string
  heading: string
  description: Array<string | JSX.Element>
}

const ComponentInteractions = ({
  interactions
}: {
  interactions: IInteractionItem[]
}) => {
  return (
    <>
      <DsDivider />
      <DsBox
        sx={{
          width: '100%',
          borderRadius: 'var(--ds-radius-bitterCold)',
          border: '1px solid var(--ds-colour-strokeDefault)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          my: {
            xs: 'var(--ds-spacing-tropical)',
            md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))'
          }
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 'var(--ds-spacing-mild)',
            gap: 'var(--ds-spacing-glacial)'
          }}
        >
          <DsRemixIcon
            className='ri-mouse-line'
            sx={{
              fontSize: '28px'
            }}
          />
          <DsTypography
            variant='headingBoldMedium'
            color='var(--ds-colour-typoPrimary)'
          >
            Interaction
          </DsTypography>
        </DsBox>
        <DsDivider
          sx={{
            width: '95%',
            mx: 'auto',
            opacity: 0.6
          }}
        />

        <DsGrid
          container
          spacing={{ xs: 5, md: 6 }}
          sx={{
            width: '100%',
            padding: 'var(--ds-spacing-mild)'
          }}
        >
          {interactions.map(item => (
            <DsGrid
              item
              xs={12}
              md={interactions.length === 1 ? 12 : 6}
              key={item.heading}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  gap: 'var(--ds-spacing-quickFreeze)',
                  mb: 'var(--ds-spacing-glacial)'
                }}
              >
                <DsRemixIcon className={item.icon} sx={{ fontSize: '20px' }} />
                <DsTypography variant='bodyBoldMedium'>
                  {item.heading}
                </DsTypography>
              </DsBox>
              <DsBox>
                {item.description?.map(
                  (text: string | JSX.Element, index: number) => (
                    <DsTypography
                      component={'div'}
                      key={`component-description-${index}`}
                      variant='bodyRegularMedium'
                      color='var(--ds-colour-typoSecondary)'
                    >
                      {text}
                    </DsTypography>
                  )
                )}
              </DsBox>
            </DsGrid>
          ))}
        </DsGrid>
      </DsBox>
    </>
  )
}

export default ComponentInteractions
