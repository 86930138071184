import React, { lazy, PureComponent, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { DsBox, DsStack, IwithBreakpoints } from '@am92/react-design-system'

import withDeviceDetails, {
  IWithDeviceDetails
} from '../Hocs/withDeviceDetails'
import withRouter, { IWithRouterProps } from '../Hocs/withRouter'

import Loader from '../Components/Loader'
import LottieBanner from '../Components/LottieBanner'
import MobileNavbar from '../Components/MobileNavbar'

import { ABOUT_SUBZERO_DESKTOP } from '../Assets/DESKTOP_BANNERS'
import { ABOUT_SUBZERO_MOBILE } from '../Assets/MOBILE_BANNERS'

import APP_ROUTES, { BASE_PATHS } from '../Constants/APP_ROUTES'
import {
  COMPONENT_DEFAULT_WIDTH,
  DEFAULT_GET_STARTED_WIDTH
} from '../Constants/THEME'

const Navbar = lazy(() => import('../Components/Navbar'))

interface IMainLayoutProps
  extends IwithBreakpoints,
    IWithDeviceDetails,
    IWithRouterProps {}

class MainLayout extends PureComponent<IMainLayoutProps> {
  constructor(props: IMainLayoutProps) {
    super(props)
  }

  getBannerImagesBasedOnRoute() {
    const {
      isMobile,
      location: { pathname }
    } = this.props
    const route = Object.values(APP_ROUTES).find(
      route => route.pathname === pathname
    )
    return isMobile
      ? route?.bannerImages?.mobile || ABOUT_SUBZERO_MOBILE
      : route?.bannerImages?.desktop || ABOUT_SUBZERO_DESKTOP
  }

  getLottieBasedOnRoute() {
    const {
      location: { pathname }
    } = this.props

    const route = Object.values(APP_ROUTES).find(
      route => route.pathname === pathname
    )
    return route?.lottie || ''
  }

  getWidthBasedOnRoute() {
    const { pathname } = this.props.location
    const corePath = pathname.startsWith('/') ? pathname.split('/')[1] : ''

    switch (corePath) {
      case BASE_PATHS.GET_STARTED:
        return DEFAULT_GET_STARTED_WIDTH
      case BASE_PATHS.FOUNDATIONS:
        if (pathname === APP_ROUTES.ELEVATION.pathname) {
          return DEFAULT_GET_STARTED_WIDTH
        } else if (
          pathname === APP_ROUTES.DESIGN_TOKENS.pathname ||
          pathname === APP_ROUTES.ACCESSIBILITY.pathname
        ) {
          return DEFAULT_GET_STARTED_WIDTH
        } else {
          return '100%'
        }
      case BASE_PATHS.COMPONENTS:
        return COMPONENT_DEFAULT_WIDTH
      default:
        return DEFAULT_GET_STARTED_WIDTH
    }
  }

  render() {
    const isMobile = this.props.isMobile || this.props.isTablet
    const dynamicWidth = this.getWidthBasedOnRoute()
    const bannerImages = this.getBannerImagesBasedOnRoute()
    const lottie = this.getLottieBasedOnRoute()

    return (
      <DsBox sx={{ display: 'flex' }}>
        {!isMobile && (
          <DsBox width={'320px'}>
            <Suspense fallback={<Loader />}>
              <Navbar />
            </Suspense>
          </DsBox>
        )}
        <DsBox sx={{ width: { xs: '100%', lg: 'calc(100% - 320px)' } }}>
          {isMobile && <MobileNavbar />}
          <DsStack
            id='main-content'
            sx={{
              width: '100%',
              height: { xs: 'calc(100vh - 64px)', lg: '100vh' },
              scrollBehavior: 'smooth',
              overflowY: 'auto'
            }}
          >
            <LottieBanner
              bannerImages={bannerImages}
              isMobile={this.props.isMobile}
              isMobileTablet={isMobile}
              lottie={lottie}
            />

            <DsBox
              sx={{
                width: {
                  xs: '100%',
                  lg: dynamicWidth
                },
                p: {
                  lg: 0,
                  xs: 'var(--ds-spacing-bitterCold)'
                },
                pl: {
                  lg: 'var(--ds-spacing-superheated)'
                }
              }}
            >
              <Outlet />
            </DsBox>
          </DsStack>
        </DsBox>
      </DsBox>
    )
  }
}

export default withDeviceDetails(withRouter(MainLayout))
