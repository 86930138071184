import React from 'react'
import { DsStack } from '@am92/react-design-system'

import withDeviceDetails, {
  IWithDeviceDetails
} from '../Hocs/withDeviceDetails'

import { AnatomyStructure } from '~/src/Components/AnatomyStructure'
import ComponentGuidelines from '~/src/Components/ComponentGuidelines'
import ComponentInteractions from '~/src/Components/ComponentInteractions'
import ComponentPlayground, {
  IPlayGroundProps
} from '~/src/Components/ComponentPlayground'
import Description from '~/src/Components/Description'
import StoryBookFigmaButton from '~/src/Components/StoryBookFigmaButton'

import { FADE_IN_UP_ANIMATION } from '../Constants/THEME'

export interface IImageProps {
  src: string
  alt: string
  as: string
}

interface IInteractiveComponentProps {
  playgroundProps: IPlayGroundProps
  anatomyStructureProps?: IImageProps[]
}

interface IInteractionProps {
  icon: string
  heading: string
  description: Array<string | JSX.Element>
}

interface IGuidelinesData {
  title: string
  image?: IImageProps[]
  imageList?: IImageProps[][]
  description: string | JSX.Element
}

interface IDynamicComponentProps extends IWithDeviceDetails {
  pageDetails: {
    figmaLink: string
    storyBookLink?: string
    descriptionTitle: string
    isMobileOnly?: boolean
    descriptionDetails: Array<string | JSX.Element>
    componentVariation: IInteractiveComponentProps[]
    componentInteraction?: IInteractionProps[]
    componentGuidelines: IGuidelinesData[]
  }
}

const DynamicComponent = ({
  pageDetails,
  isMobile
}: IDynamicComponentProps) => {
  return (
    <DsStack
      key={pageDetails.descriptionTitle}
      sx={{
        ...FADE_IN_UP_ANIMATION
      }}
    >
      <StoryBookFigmaButton
        figmaLink={pageDetails.figmaLink}
        storyBookLink={pageDetails.storyBookLink}
        isMobile={isMobile}
      />

      <Description
        isMobileOnly={pageDetails.isMobileOnly}
        title={pageDetails.descriptionTitle}
        titleVariant='headingBoldLarge'
        mt={
          isMobile
            ? 'var(--ds-spacing-pleasant)'
            : 'calc(var(--ds-spacing-tepid) + var(--ds-spacing-deepFreeze))'
        }
        mb={
          isMobile
            ? 'var(--ds-spacing-tropical)'
            : 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))'
        }
        details={pageDetails.descriptionDetails}
      />
      {pageDetails.componentVariation.map(
        (variation: IInteractiveComponentProps, index: number) => {
          return (
            <React.Fragment key={`interation-${index}`}>
              <ComponentPlayground
                playgroundProps={variation.playgroundProps}
                sxProps={
                  variation.anatomyStructureProps
                    ? {}
                    : {
                        mb: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))'
                      }
                }
              />
              {variation.anatomyStructureProps && (
                <AnatomyStructure
                  anatomyStructureProp={variation.anatomyStructureProps}
                />
              )}
            </React.Fragment>
          )
        }
      )}
      {pageDetails.componentInteraction && (
        <ComponentInteractions
          interactions={pageDetails.componentInteraction}
        />
      )}
      <ComponentGuidelines guidelines={pageDetails.componentGuidelines} />
    </DsStack>
  )
}

export default withDeviceDetails(DynamicComponent)
