import React from 'react'
import Lottie from 'react-lottie'
import { DsBox, DsIconButton, DsImage } from '@am92/react-design-system'

interface ILottieProps {
  isMobileTablet: boolean
  isMobile: boolean
  bannerImages: {
    src: string
    alt: string
    as: string
  }[]
  lottie: any
}

function LottieBanner({
  isMobileTablet,
  isMobile,
  bannerImages,
  lottie
}: ILottieProps) {
  return (
    <>
      {isMobileTablet || !!lottie ? (
        <DsIconButton
          disabled
          sx={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: 'var(--ds-spacing-zero)'
          }}
        >
          <DsImage
            srcSet={bannerImages}
            style={{
              width: '100%',
              height: '100%'
            }}
            aspectRatio={isMobile ? 103 / 70 : 24 / 7}
          />
          {!!lottie && (
            <DsBox
              sx={{
                position: 'absolute',
                right: isMobile ? '-17%' : '-10.42%',
                width: isMobile ? '105%' : '60%',
                height: isMobile ? '61.1%' : '80%'
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottie
                }}
              />
            </DsBox>
          )}
        </DsIconButton>
      ) : (
        <DsBox
          sx={{
            width: '100%',
            /* The image used */
            backgroundImage: `url(${bannerImages[0].src})`,

            /* Set a specific height */
            minHeight: 'calc((100vw - 320px) * 7 / 24)',

            /* Create the parallax scrolling effect */
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '320px',
            backgroundPositionY: '0px',
            backgroundSize: 'calc(100vw - 320px) calc((100vw - 320px) * 7 / 24)'
          }}
        ></DsBox>
      )}
    </>
  )
}

export default LottieBanner
