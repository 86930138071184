import { NavigateOptions, To } from 'react-router-dom'

import {
  ABOUT_SUBZERO_DESKTOP,
  ACCESSIBILITY_DESKTOP,
  ACCORDION_DESKTOP,
  ADD_ITEM_DESKTOP,
  APP_BAR_DESKTOP,
  AVATAR_DESKTOP,
  BADGE_DESKTOP,
  BOTTOM_NAV_DESKTOP,
  BOTTOM_SHEET_DESKTOP,
  BREADCRUMB_DESKTOP,
  BUTTON_DESKTOP,
  CAROUSEL_DESKTOP,
  CHECKBOX_DESKTOP,
  CHIP_DESKTOP,
  COACHMARK_DESKTOP,
  COLOUR_DESKTOP,
  DATA_VIS_DESKTOP,
  DATEPICKER_DESKTOP,
  DESIGN_TOKEN_DESKTOP,
  DESIGNERS_TOOLKIT_DESKTOP,
  DEVELOPERS_TOOLKIT_DESKTOP,
  DIALOG_DESKTOP,
  DIVIDER_DESKTOP,
  ELEVATION_DESKTOP,
  FAB_DESKTOP,
  FEEDBACK_DESKTOP,
  HEADER_DESKTOP,
  ICONOGRAPHY_DESKTOP,
  INPUT_FIELD_DESKTOP,
  LAYOUTSPACING_DESKTOP,
  LINK_DESKTOP,
  MENU_DESKTOP,
  ONBOARDING_DESKTOP,
  PROGRESS_TRACKER_DESKTOP,
  RADIO_DESKTOP,
  SEARCH_DESKTOP,
  SLIDER_DESKTOP,
  SWITCH_DESKTOP,
  TAB_DESKTOP,
  TAGS_DESKTOP,
  TOAST_DESKTOP,
  TOGGLE_DESKTOP,
  TOOLTIP_DESKTOP,
  TYPOGRAPHY_DESKTOP
} from '../Assets/DESKTOP_BANNERS'
import {
  ABOUT_SUBZERO_MOBILE,
  ACCESSIBILITY_MOBILE,
  ACCORDION_MOBILE,
  ADD_ITEM_MOBILE,
  APP_BAR_MOBILE,
  AVATAR_MOBILE,
  BADGE_MOBILE,
  BOTTOM_NAV_MOBILE,
  BOTTOM_SHEET_MOBILE,
  BREADCRUMB_MOBILE,
  BUTTON_MOBILE,
  CAROUSEL_MOBILE,
  CHECKBOX_MOBILE,
  CHIP_MOBILE,
  COACHMARK_MOBILE,
  COLOUR_MOBILE,
  DATA_VIS_MOBILE,
  DATEPICKER_MOBILE,
  DESIGN_TOKEN_MOBILE,
  DESIGNERS_TOOLKIT_MOBILE,
  DEVELOPERS_TOOLKIT_MOBILE,
  DIALOG_MOBILE,
  DIVIDER_MOBILE,
  ELEVATION_MOBILE,
  FAB_MOBILE,
  FEEDBACK_MOBILE,
  HEADER_MOBILE,
  ICONOGRAPHY_MOBILE,
  INPUT_FIELD_MOBILE,
  LAYOUTSPACING_MOBILE,
  LINK_MOBILE,
  MENU_MOBILE,
  ONBOARDING_MOBILE,
  PROGRESS_TRACKER_MOBILE,
  RADIO_MOBILE,
  SEARCH_MOBILE,
  SLIDER_MOBILE,
  SWITCH_MOBILE,
  TAB_MOBILE,
  TAGS_MOBILE,
  TOAST_MOBILE,
  TOGGLE_MOBILE,
  TOOLTIP_MOBILE,
  TYPOGRAPHY_MOBILE
} from '../Assets/MOBILE_BANNERS'

import accordion from '~/public/assets/lotties/accordion.json'
import addItem from '~/public/assets/lotties/addItem.json'
import appBar from '~/public/assets/lotties/appBar.json'
import avatar from '~/public/assets/lotties/avatar.json'
import badge from '~/public/assets/lotties/badge.json'
import bottomNavigation from '~/public/assets/lotties/bottomNavigation.json'
import bottomSheet from '~/public/assets/lotties/bottomSheet.json'
import breadcrumb from '~/public/assets/lotties/breadcrumb.json'
import button from '~/public/assets/lotties/button.json'
import carousel from '~/public/assets/lotties/carousel.json'
import checkbox from '~/public/assets/lotties/checkbox.json'
import chip from '~/public/assets/lotties/chip.json'
import coachmark from '~/public/assets/lotties/coachmark.json'
import datePicker from '~/public/assets/lotties/datePicker.json'
import dialog from '~/public/assets/lotties/dialog.json'
import divider from '~/public/assets/lotties/divider.json'
import fab from '~/public/assets/lotties/fab.json'
import header from '~/public/assets/lotties/header.json'
import inputField from '~/public/assets/lotties/inputField.json'
import link from '~/public/assets/lotties/link.json'
import menu from '~/public/assets/lotties/menu.json'
import onboarding from '~/public/assets/lotties/onboarding.json'
import progressTracker from '~/public/assets/lotties/progressTracker.json'
import radio from '~/public/assets/lotties/radio.json'
import search from '~/public/assets/lotties/search.json'
import slider from '~/public/assets/lotties/slider.json'
import YesNo from '~/public/assets/lotties/switch.json'
import tab from '~/public/assets/lotties/tabs.json'
import tags from '~/public/assets/lotties/tags.json'
import toast from '~/public/assets/lotties/toast.json'
import toggle from '~/public/assets/lotties/toggle.json'
import tooltip from '~/public/assets/lotties/tooltip.json'

type TAppRouteItem = {
  pathname: To
  bannerImages?: {
    mobile: {
      src: string
      alt: string
      as: string
    }[]
    desktop: {
      src: string
      alt: string
      as: string
    }[]
  }
  lottie?: any
  options?: NavigateOptions
}

type TAppRoute = {
  [key: string]: TAppRouteItem
}
export const BASE_PATHS = {
  GET_STARTED: 'get-started',
  FOUNDATIONS: 'foundations',
  COMPONENTS: 'components'
}

const APP_ROUTES: TAppRoute = {
  HOME: {
    pathname: '/'
  },

  ABOUT_SUBZERO: {
    pathname: `/${BASE_PATHS.GET_STARTED}/about-subzero`,
    bannerImages: {
      mobile: ABOUT_SUBZERO_MOBILE,
      desktop: ABOUT_SUBZERO_DESKTOP
    }
  },

  DESIGNERS_TOOLKIT: {
    pathname: `/${BASE_PATHS.GET_STARTED}/designers-toolkit`,
    bannerImages: {
      mobile: DESIGNERS_TOOLKIT_MOBILE,
      desktop: DESIGNERS_TOOLKIT_DESKTOP
    }
  },

  DEVELOPERS_TOOLKIT: {
    pathname: `/${BASE_PATHS.GET_STARTED}/developers-toolkit`,
    bannerImages: {
      mobile: DEVELOPERS_TOOLKIT_MOBILE,
      desktop: DEVELOPERS_TOOLKIT_DESKTOP
    }
  },

  FEEDBACK: {
    pathname: `/${BASE_PATHS.GET_STARTED}/feedback`,
    bannerImages: {
      mobile: FEEDBACK_MOBILE,
      desktop: FEEDBACK_DESKTOP
    }
  },

  TYPOGRAPHY: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/typography`,
    bannerImages: {
      mobile: TYPOGRAPHY_MOBILE,
      desktop: TYPOGRAPHY_DESKTOP
    }
  },

  COLOURS: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/colours`,
    bannerImages: {
      mobile: COLOUR_MOBILE,
      desktop: COLOUR_DESKTOP
    }
  },

  ELEVATION: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/elevation`,
    bannerImages: {
      mobile: ELEVATION_MOBILE,
      desktop: ELEVATION_DESKTOP
    }
  },

  ICONOGRAPHY: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/iconography`,
    bannerImages: {
      mobile: ICONOGRAPHY_MOBILE,
      desktop: ICONOGRAPHY_DESKTOP
    }
  },

  LAYOUT_SPACING: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/layout-and-spacing`,
    bannerImages: {
      mobile: LAYOUTSPACING_MOBILE,
      desktop: LAYOUTSPACING_DESKTOP
    }
  },

  DATA_VISUALISATION: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/data-visualisation`,
    bannerImages: {
      mobile: DATA_VIS_MOBILE,
      desktop: DATA_VIS_DESKTOP
    }
  },

  DESIGN_TOKENS: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/design-tokens`,
    bannerImages: {
      mobile: DESIGN_TOKEN_MOBILE,
      desktop: DESIGN_TOKEN_DESKTOP
    }
  },

  ACCESSIBILITY: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/accessibility`,
    bannerImages: {
      mobile: ACCESSIBILITY_MOBILE,
      desktop: ACCESSIBILITY_DESKTOP
    }
  },

  ACCORDION: {
    pathname: `/${BASE_PATHS.COMPONENTS}/accordion`,
    lottie: accordion,
    bannerImages: {
      mobile: ACCORDION_MOBILE,
      desktop: ACCORDION_DESKTOP
    }
  },
  BUTTON: {
    pathname: `/${BASE_PATHS.COMPONENTS}/button`,
    lottie: button,
    bannerImages: {
      mobile: BUTTON_MOBILE,
      desktop: BUTTON_DESKTOP
    }
  },

  APP_BAR: {
    pathname: `/${BASE_PATHS.COMPONENTS}/app-bar`,
    lottie: appBar,
    bannerImages: {
      mobile: APP_BAR_MOBILE,
      desktop: APP_BAR_DESKTOP
    }
  },

  BADGE: {
    pathname: `/${BASE_PATHS.COMPONENTS}/badge`,
    lottie: badge,
    bannerImages: {
      mobile: BADGE_MOBILE,
      desktop: BADGE_DESKTOP
    }
  },

  BOTTOM_NAVIGATION: {
    pathname: `/${BASE_PATHS.COMPONENTS}/bottom-navigation`,
    lottie: bottomNavigation,
    bannerImages: {
      mobile: BOTTOM_NAV_MOBILE,
      desktop: BOTTOM_NAV_DESKTOP
    }
  },

  BREADCRUMB: {
    pathname: `/${BASE_PATHS.COMPONENTS}/breadcrumb`,
    lottie: breadcrumb,
    bannerImages: {
      mobile: BREADCRUMB_MOBILE,
      desktop: BREADCRUMB_DESKTOP
    }
  },

  AVATAR: {
    pathname: `/${BASE_PATHS.COMPONENTS}/avatar`,
    lottie: avatar,
    bannerImages: {
      mobile: AVATAR_MOBILE,
      desktop: AVATAR_DESKTOP
    }
  },

  CHIP: {
    pathname: `/${BASE_PATHS.COMPONENTS}/chip`,
    lottie: chip,
    bannerImages: {
      mobile: CHIP_MOBILE,
      desktop: CHIP_DESKTOP
    }
  },

  DIALOG: {
    pathname: `/${BASE_PATHS.COMPONENTS}/dialog`,
    lottie: dialog,
    bannerImages: {
      mobile: DIALOG_MOBILE,
      desktop: DIALOG_DESKTOP
    }
  },

  TOGGLE: {
    pathname: `/${BASE_PATHS.COMPONENTS}/toggle`,
    lottie: toggle,
    bannerImages: {
      mobile: TOGGLE_MOBILE,
      desktop: TOGGLE_DESKTOP
    }
  },

  BOTTOM_SHEET: {
    pathname: `/${BASE_PATHS.COMPONENTS}/bottom-sheet`,
    lottie: bottomSheet,
    bannerImages: {
      mobile: BOTTOM_SHEET_MOBILE,
      desktop: BOTTOM_SHEET_DESKTOP
    }
  },

  INPUT_FIELD: {
    pathname: `/${BASE_PATHS.COMPONENTS}/input-field`,
    lottie: inputField,
    bannerImages: {
      mobile: INPUT_FIELD_MOBILE,
      desktop: INPUT_FIELD_DESKTOP
    }
  },

  TAGS: {
    pathname: `/${BASE_PATHS.COMPONENTS}/tag`,
    lottie: tags,
    bannerImages: {
      mobile: TAGS_MOBILE,
      desktop: TAGS_DESKTOP
    }
  },

  CAROUSEL: {
    pathname: `/${BASE_PATHS.COMPONENTS}/carousel`,
    lottie: carousel,
    bannerImages: {
      mobile: CAROUSEL_MOBILE,
      desktop: CAROUSEL_DESKTOP
    }
  },

  FAB: {
    pathname: `/${BASE_PATHS.COMPONENTS}/fab`,
    lottie: fab,
    bannerImages: {
      mobile: FAB_MOBILE,
      desktop: FAB_DESKTOP
    }
  },

  DIVIDER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/divider`,
    lottie: divider,
    bannerImages: {
      mobile: DIVIDER_MOBILE,
      desktop: DIVIDER_DESKTOP
    }
  },

  COACHMARK: {
    pathname: `/${BASE_PATHS.COMPONENTS}/coachmark`,
    lottie: coachmark,
    bannerImages: {
      mobile: COACHMARK_MOBILE,
      desktop: COACHMARK_DESKTOP
    }
  },

  ONBOARDING: {
    pathname: `/${BASE_PATHS.COMPONENTS}/onboarding`,
    lottie: onboarding,
    bannerImages: {
      mobile: ONBOARDING_MOBILE,
      desktop: ONBOARDING_DESKTOP
    }
  },

  MENU: {
    pathname: `/${BASE_PATHS.COMPONENTS}/menu`,
    lottie: menu,
    bannerImages: {
      mobile: MENU_MOBILE,
      desktop: MENU_DESKTOP
    }
  },

  RADIO_BUTTON: {
    pathname: `/${BASE_PATHS.COMPONENTS}/radio-button`,
    lottie: radio,
    bannerImages: {
      mobile: RADIO_MOBILE,
      desktop: RADIO_DESKTOP
    }
  },

  ADD_ITEM: {
    pathname: `/${BASE_PATHS.COMPONENTS}/add-item`,
    lottie: addItem,
    bannerImages: {
      mobile: ADD_ITEM_MOBILE,
      desktop: ADD_ITEM_DESKTOP
    }
  },

  CHECKBOX: {
    pathname: `/${BASE_PATHS.COMPONENTS}/checkbox`,
    lottie: checkbox,
    bannerImages: {
      mobile: CHECKBOX_MOBILE,
      desktop: CHECKBOX_DESKTOP
    }
  },

  SWITCH: {
    pathname: `/${BASE_PATHS.COMPONENTS}/switch`,
    lottie: YesNo,
    bannerImages: {
      mobile: SWITCH_MOBILE,
      desktop: SWITCH_DESKTOP
    }
  },

  SEARCH: {
    pathname: `/${BASE_PATHS.COMPONENTS}/search`,
    lottie: search,
    bannerImages: {
      mobile: SEARCH_MOBILE,
      desktop: SEARCH_DESKTOP
    }
  },

  SLIDER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/slider`,
    lottie: slider,
    bannerImages: {
      mobile: SLIDER_MOBILE,
      desktop: SLIDER_DESKTOP
    }
  },

  TABS: {
    pathname: `/${BASE_PATHS.COMPONENTS}/tabs`,
    lottie: tab,
    bannerImages: {
      mobile: TAB_MOBILE,
      desktop: TAB_DESKTOP
    }
  },

  TOAST: {
    pathname: `/${BASE_PATHS.COMPONENTS}/toast`,
    lottie: toast,
    bannerImages: {
      mobile: TOAST_MOBILE,
      desktop: TOAST_DESKTOP
    }
  },

  TOOLTIP: {
    pathname: `/${BASE_PATHS.COMPONENTS}/tooltip`,
    lottie: tooltip,
    bannerImages: {
      mobile: TOOLTIP_MOBILE,
      desktop: TOOLTIP_DESKTOP
    }
  },

  HEADER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/header`,
    lottie: header,
    bannerImages: {
      mobile: HEADER_MOBILE,
      desktop: HEADER_DESKTOP
    }
  },

  LINK: {
    pathname: `/${BASE_PATHS.COMPONENTS}/link`,
    lottie: link,
    bannerImages: {
      mobile: LINK_MOBILE,
      desktop: LINK_DESKTOP
    }
  },

  PROGRESS_TRACKER_MOBILE: {
    pathname: `/${BASE_PATHS.COMPONENTS}/progress-tracker`,
    lottie: progressTracker,
    bannerImages: {
      mobile: PROGRESS_TRACKER_MOBILE,
      desktop: PROGRESS_TRACKER_DESKTOP
    }
  },

  DATE_PICKER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/date-picker`,
    lottie: datePicker,
    bannerImages: {
      mobile: DATEPICKER_MOBILE,
      desktop: DATEPICKER_DESKTOP
    }
  },

  V2: {
    pathname: '/v2/*'
  },

  ANY: {
    pathname: '*'
  }
}

export default APP_ROUTES
