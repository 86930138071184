import {
  checkboxButtonPlaygroundProps,
  checkboxPlaygroundProps,
  multiSelectionPlaygroundProps
} from './PlaygroundDetails'

import {
  CHECKBOX_GUIDELINE,
  CHECKBOX1,
  CHECKBOX2,
  CHECKBOX3
} from '~/src/Assets/CHECKBOX'

const interaction = [
  {
    icon: 'ri-cursor-line',
    heading: 'Mouse',
    description: [
      'Clicking anywhere within the button selects/ticks or deselects the checkbox.'
    ]
  }
]

const guidelinesData = [
  {
    title: 'Use label with Checkbox',
    imageList: CHECKBOX_GUIDELINE[0],
    description:
      'The checkbox should always have a label to indicate its connection to the component.'
  },
  {
    title: 'Place Checkbox vertically',
    imageList: CHECKBOX_GUIDELINE[1],
    description:
      'Arrange the checkbox vertically for easier scanning and selection. Avoid horizontal placement, which may confuse label associations and increase cognitive load.'
  },
  {
    title: 'Select multiple options',
    imageList: CHECKBOX_GUIDELINE[2],
    description:
      "If a list consists of multiple options, don't use switches. Instead, use checkboxes. Checkboxes imply that the items are related and take up less visual space."
  }
]

export const CheckboxComponentPage = {
  figmaLink:
    'https://www.figma.com/design/9o1qfErgy23YgsDzoaXpw7/Subzero-V.2.0-Design-System?node-id=4492-28218&node-type=canvas&t=rGxyBpUMumO1TyvP-0',
  storyBookLink:
    'https://storybook.axisbank.com/v2/?path=/docs/design-system-checkbox--docs',
  descriptionTitle: 'Checkbox',
  descriptionDetails: [
    'Checkboxes are interactive elements that enable users to select multiple options from a list. They are used in various contexts, such as forms, settings, and preferences. In contrast, radio buttons allow for only a single selection from a group of options.'
  ],
  componentVariation: [
    {
      playgroundProps: checkboxPlaygroundProps,
      anatomyStructureProps: CHECKBOX1
    },
    {
      playgroundProps: checkboxButtonPlaygroundProps,
      anatomyStructureProps: CHECKBOX2
    },
    {
      playgroundProps: multiSelectionPlaygroundProps,
      anatomyStructureProps: CHECKBOX3
    }
  ],
  componentInteraction: interaction,
  componentGuidelines: guidelinesData
}
