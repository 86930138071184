import React, { useEffect, useState } from 'react'
import {
  DsBox,
  DsDrawer,
  DsRemixIcon,
  DsStack
} from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '../Hocs/withRouter'

import HeaderIcon from './HeaderIcon'
import Navbar from './Navbar'

interface IMobileNavbar extends IWithRouterProps {
  hambergurColor?: string
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
}

const MobileNavbar = ({
  position,
  hambergurColor,
  location
}: IMobileNavbar) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isInFirstViewport, setIsInFirstViewport] = useState(true)
  const isLandingScreen = location.pathname === '/'

  const toggleNavbar = () => {
    setIsOpen(prev => !prev)
  }
  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const viewportHeight = window.innerHeight
    if (scrollPosition / viewportHeight > 0.5) {
      setIsInFirstViewport(false)
    } else {
      setIsInFirstViewport(true)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <DsStack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          height: '64px',
          width: '100%',
          zIndex: 10,
          position: position || 'sticky',
          top: '0',
          px: 'var(--ds-spacing-bitterCold)',
          background: isInFirstViewport
            ? 'transparent'
            : 'var(--ds-colour-surfaceBackground)',
          transition: 'background-color 0.2s ease-out'
        }}
      >
        <DsRemixIcon
          className='ri-menu-line'
          sx={{
            fontSize: '24px',
            cursor: 'pointer',
            color:
              isInFirstViewport && isLandingScreen ? '#282828' : hambergurColor
          }}
          onClick={toggleNavbar}
        />
        <DsBox
          sx={{
            width: { xs: '119px', lg: '166px' },
            height: { xs: '24px', lg: '36px' }
          }}
        >
          <HeaderIcon
            color={
              hambergurColor && isLandingScreen && isInFirstViewport
                ? '#282828'
                : 'var(--ds-colour-typoPrimary)'
            }
          />
        </DsBox>
      </DsStack>

      <DsDrawer
        anchor='left'
        open={isOpen}
        onClose={toggleNavbar}
        sx={{
          width: '0px',
          height: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Navbar open={isOpen} onClose={toggleNavbar} />
      </DsDrawer>
    </>
  )
}

export default withRouter(MobileNavbar)
