export const ABOUT_SUBZERO_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/aboutSubzeroDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DESIGNERS_TOOLKIT_DESKTOP = [
  {
    src: new URL(
      '~/src/Assets/images/designersToolkitDesktop.jpg',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DEVELOPERS_TOOLKIT_DESKTOP = [
  {
    src: new URL(
      '~/src/Assets/images/developerToolkitDesktop.jpg',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const FEEDBACK_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/feedbackDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DESIGN_TOKEN_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/designTokenDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ACCESSIBILITY_DESKTOP = [
  {
    src: new URL(
      '~/src/Assets/images/accessibilityDesktop.jpg',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TYPOGRAPHY_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/typographyDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const COLOUR_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/colourDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ELEVATION_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/elevationDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ICONOGRAPHY_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/iconographyDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const LAYOUTSPACING_DESKTOP = [
  {
    src: new URL(
      '~/src/Assets/images/layoutSpacingDesktop.jpg',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DATA_VIS_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/dataVisDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BUTTON_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/buttonDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const COACHMARK_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/buttonDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ADD_ITEM_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/addItemDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const CHIP_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/addItemDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BADGE_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/badgeDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const APP_BAR_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/badgeDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const INPUT_FIELD_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/inputFieldDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const CAROUSEL_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/inputFieldDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const AVATAR_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BREADCRUMB_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BOTTOM_SHEET_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const BOTTOM_NAV_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/bottomNavDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const CHECKBOX_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/badgeDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const MENU_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/inputFieldDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const SWITCH_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/badgeDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TOAST_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const TAB_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/bottomNavDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const SLIDER_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/bottomNavDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DIALOG_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/bottomNavDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ONBOARDING_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/buttonDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const FAB_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/buttonDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const RADIO_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/addItemDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TAGS_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/addItemDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TOGGLE_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/badgeDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const ACCORDION_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const HEADER_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/buttonDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const PROGRESS_TRACKER_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Bannerr Image',
    as: 'progressive'
  }
]

export const LINK_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/addItemDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
export const DIVIDER_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/bottomNavDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const DATEPICKER_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/badgeDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const TOOLTIP_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/inputFieldDesktop.jpg', import.meta.url)
      .href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]

export const SEARCH_DESKTOP = [
  {
    src: new URL('~/src/Assets/images/avatarDesktop.jpg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'progressive'
  }
]
