import { APP_TITLE } from '~/src/Configurations/env'

export const THEME_MODE_STORAGE_KEY: string = `${APP_TITLE.replaceAll(
  ' ',
  '-'
)}-mode`

export const DEFAULT_GET_STARTED_WIDTH = '66.67%'

export const DEFAULT_FOUNDATION_WIDTH = '63.64%'

export const COMPONENT_DEFAULT_WIDTH = '91.68%'

export const BOOKMARK_MENU_WIDTH = '26.82%'

export const FADE_IN_UP_ANIMATION = {
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(25vh)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  animation: 'fadeInUp 0.25s ease-out'
}

export const FADE_IN_UP_BIG_ANIMATION = {
  '@keyframes fadeInUpBig': {
    '0%': {
      opacity: 0,
      transform: 'translate3d(0, 2000px, 0)'
    },
    '80%': {
      opacity: 0.3
    },
    '100%': {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)'
    }
  },
  animationName: 'fadeInUpBig',
  animationTimingFunction: 'cubic-bezier(0.22, 0.61, 0.36, 1)',
  animationFillMode: 'both'
}

export const FADE_IN_ANIMATION = {
  '@keyframes fadeIn': {
    from: {
      opacity: 0
    },

    to: {
      opacity: 1
    }
  },

  animation: 'fadeIn 1s ease-out'
}

export const SLIDE_IN_LEFT_ANIMATION = {
  '@keyframes slideInLeft': {
    '0%': {
      transform: 'translateX(-50%)',
      visibility: 'visible'
    },

    '100%': {
      transform: 'translateX(0, 0, 0)'
    }
  },
  animation: 'slideInLeft 0.25s ease-out'
}

export const SLIDE_IN_RIGHT_ANIMATION = {
  '@keyframes slideInRight': {
    '0%': {
      transform: 'translateX(50%)',
      visibility: 'visible'
    },

    '100%': {
      transform: 'translateX(0, 0, 0)'
    }
  },
  animation: 'slideInRight 0.25s ease-out'
}

export const SLIDE_IN_TOP_ANIMATION = {
  '@keyframes slideInTop': {
    '0%': {
      transform: 'translateY(-100%)',
      visibility: 'visible'
    },

    '100%': {
      transform: 'translateY(0, 0, 0)'
    }
  },
  animation: 'slideInTop .4s cubic-bezier(.2,0,0,1)'
}

export const SLIDE_IN_DOWN_ANIMATION = {
  '@keyframes slideInDown': {
    '0%': {
      transform: 'translateY(100%)',
      visibility: 'visible'
    },

    '100%': {
      transform: 'translateY(0, 0, 0)'
    }
  },
  animation: 'slideInDown .4s cubic-bezier(.2,0,0,1)'
}
